<template>
  <div>
    <profile></profile>
  </div>
</template>

<script>
import {
  default as Profile
} from "@/components/widgets/Profile";
import { mapGetters } from 'vuex';
export default {
  name: 'WaiProfile',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    Profile
  },
  props: {},
  data () {
    return {};
  },
  created: function () {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      let route_data = {
        'name': 'waiprofile',
        'path': '/p/',
        'params': { 'username': user.username },
        'meta': {
          'wai': 1
        }
      };
      this.$router.push(route_data);
    }
  },
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
};

</script>

